<template>
  <div>
    <h2 class="profile">Live Income</h2>
    <div class="incomeList pc">
      <el-table :data="tableData" style="width: 85%" v-loading="loading">
        <!-- <el-table-column prop="roomName" align="center" label="roomName" width="180" /> -->
        <el-table-column prop="roomStartTime" align="center" label="Start Time ">
          <template #default="scope">
            <span v-if="scope.row.startTime">{{
              timeFormat(scope.row.roomStartTime)
            }}</span>
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column prop="hh" align="center" label="Duration(mins)" />
        <el-table-column prop="totalCoinBalance" align="center" label="Total Income" />

        <!-- <el-table-column prop="roomEndTime" align="center" label="endTime ">
                    <template #default="scope">
                        <span v-if="scope.row.roomEndTime">{{ timeFormat(scope.row.roomEndTime) }}</span>
                        <span v-else></span>
                    </template>
                </el-table-column> -->
      </el-table>
    </div>
    <div class="page-list pc">
      <el-pagination
        v-show="state.total > 0"
        :total="state.total"
        :page="queryParams.pageNum"
        :limit="queryParams.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <div class="mobile">
      <hr />
    </div>

    <!-- 移动端 -->
    <div v-if="tableData && tableData.length > 0" class="mobile list">
      <div class="card" v-for="item in tableData" :key="item.id">
        <div>
          Start Time: <span>{{ timeFormat(item.roomStartTime) }}</span>
        </div>
        <div>
          Duration(mins): <span>{{ item.hh }}</span>
        </div>
        <div>
          Total Income: <span>{{ item.totalCoinBalance }}</span>
        </div>
      </div>
    </div>
    <div v-else class="mobile noData">No Data</div>

    <div v-if="tableData && tableData.length > 0" class="mobile paging">
      <el-pagination
        small
        v-show="state.total > 0"
        :total="state.total"
        :page="queryParams.pageNum"
        :limit="queryParams.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :pager-count="8"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue";
import { getMyLiveIncome } from "@/api/center.js";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import { reverseDateTime } from "@/components/timeTransform/index.js";
const store = useStore();
import dayjs from "dayjs";
const props = defineProps({
  // consultantId: {
  //     type: Number || '',
  //     default: ''
  // }
});
const loading = ref(false);
const tableData = ref();
const state = reactive({
  total: "",
});
const queryParams = ref({
  pageNum: "1",
  pageSize: "10",
});
onMounted(() => {
  getList();
});
const getList = async () => {
  loading.value = true;
  queryParams.value.liveType = 1;
  // queryParams.value.consultantId = props.consultantId
  queryParams.value.consultantId = JSON.parse(store.state.userInfo).id;
  let res = await getMyLiveIncome(queryParams.value);
  if (res.code == 200) {
    // console.log(res.rows, "pppp");
    const optimizedData = res.rows.map((item) => {
      const roomStartTime = new Date(item.roomStartTime).getTime() / 1000;
      const roomEndTime = new Date(item.roomEndTime).getTime() / 1000;
      const durationInMinutes = (roomEndTime - roomStartTime) / 60;

      const hh = durationInMinutes > 0 ? durationInMinutes.toFixed(2) : "--";

      return {
        ...item,
        hh: hh,
      };
    });

    tableData.value = optimizedData;

    console.log(tableData.value, "tableData.value");
    state.total = res.total;
    loading.value = false;
  } else {
    loading.value = false;
    ElMessage.error({
      message: "Sever error!",
      type: "warning",
      grouping: true,
    });
  }
};
const handleSizeChange = (val) => {
  console.log(val, "页");
  queryParams.value.pageNum = val;
  getList();
};
const handleCurrentChange = (val) => {
  console.log(val, "分");
  queryParams.value.pageNum = val;
  getList();
};
//时间转换
const timeFormat = (val) => {
  // return dayjs(String(val)).format("YYYY-MM-DD HH:mm:ss");
  return dayjs(String(val)).format("DD-MM-YYYY HH:mm:ss");
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 769px) {
  .mobile {
    display: none;
  }
  .incomeList {
    padding: 30px 20px;
  }

  .profile {
    line-height: 50px;
    border-bottom: 1px solid #c2bcbc;
    padding-left: 20px;
    color: #02758a;
  }

  .page-list {
    display: flex;
    justify-content: right;
    margin-right: 200px;
  }
}

//移动端
@media screen and (max-width: 768px) {
  .noData {
    text-align: center;
    font-size: 20px;
  }
  .pc {
    display: none;
  }

  .list {
    background: #eee;
    padding: 10px 0;
    .card {
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
      background: #ffffff;
      border-radius: 12px;
      width: 95%;
      margin: 10px auto;
      padding: 20px;

      div {
        line-height: 25px;
        font-size: 18px;

        span {
          color: red;
          margin-left: 10px;
          font-size: 15px;
        }
      }
    }
  }

  .paging {
    margin-top: 20px !important;
  }
}
</style>
